import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './pub.css'
import './commande.css';

const PubPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [selectedMediaIds, setSelectedMediaIds] = useState([]);
  const [selectedFileDuration, setSelectedFileDuration] = useState('');

  const [teamName, setTeamName] = useState('');
  const [textColor, setTextColor] = useState('#000000');
  const [selectedLogo, setSelectedLogo] = useState(null);


  const [showAds, setShowAds] = useState(false);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [adPlaylist, setAdPlaylist] = useState([]);

  const handleStartAds = async () => {
    try {
      await axios.post('https://scoreboardapi.onrender.com/api/ads-state', { adsRunning: true });
      setShowAds(true); // Mettre à jour l'état local pour afficher les publicités
    } catch (error) {
      console.error('Erreur lors du démarrage des publicités', error);
    }
  };

  const handleStopAds = async () => {
    try {
      await axios.post('https://scoreboardapi.onrender.com/api/ads-state', { adsRunning: false });
      setShowAds(false); // Mettre à jour l'état local pour arrêter d'afficher les publicités
    } catch (error) {
      console.error('Erreur lors de l\'arrêt des publicités', error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Veuillez sélectionner un fichier.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('image', selectedFile);

      let mediaDuration = null; // Durée du média

      // Vérifier si le fichier est une vidéo
      if (selectedFile.type.startsWith('video')) {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(selectedFile);

        // Attendre que la vidéo soit chargée
        await new Promise(resolve => {
          video.onloadedmetadata = () => {
            mediaDuration = Math.floor(video.duration); // Durée en secondes
            resolve();
          };
        });
      } else if (selectedFile.type.startsWith('image')) {
        // Utiliser la durée spécifiée dans l'input pour les images
        mediaDuration = selectedFileDuration;
      }

      // Envoyer la requête au backend pour télécharger le média via FTP
      const response = await axios.post(
        'https://scoreboardapi.onrender.com/api/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const imageUrl = response.data.imageUrl;
      console.log('URL du média téléchargé:', imageUrl);

      // Enregistrer le média dans le localStorage
      const mediaInfo = { url: imageUrl, duration: mediaDuration };
      const updatedLocalStorage = [...JSON.parse(localStorage.getItem('uploadedMedia') || '[]'), mediaInfo];
      localStorage.setItem('uploadedMedia', JSON.stringify(updatedLocalStorage));

      // Mettre à jour l'état local avec la nouvelle liste de médias
      setImageList(updatedLocalStorage);
    } catch (error) {
      console.error('Erreur lors du téléchargement du média:', error);
    }
  };

  const [dataReady, setDataReady] = useState(false);  // Nouvel état pour indiquer si les données sont prêtes


  useEffect(() => {
    const fetchImages = async () => {
      try {
        const uploadedMedia = JSON.parse(localStorage.getItem('uploadedMedia') || '[]');
        setImageList(uploadedMedia);
        setDataReady(true); // Marquer les données comme prêtes une fois que setImageList a été appelé
      } catch (error) {
        console.error('Erreur lors de la récupération des images:', error);
      }
    };

    fetchImages();
  }, []);







  const handleDelete = async (imageUrl) => {
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer ce média ? Cette action sera irréversible.");

    if (!confirmation) {
      return;
    }

    try {
      // Send a request to the backend to delete the image
      await axios.delete('https://scoreboardapi.onrender.com/api/delete', {
        data: { imageUrl },
      });

      // Remove the deleted image URL from the image list state
      setImageList(imageList.filter((media) => media.url !== imageUrl));
      // Also remove from playlist if it exists there
      setPlaylist(playlist.filter((media) => media.url !== imageUrl));

      // Mettre à jour le localStorage après la suppression
      const updatedLocalStorage = imageList.filter((media) => media.url !== imageUrl);
      localStorage.setItem('uploadedMedia', JSON.stringify(updatedLocalStorage));
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image:', error);
    }
  };



  const handleCheckboxChange = (imageUrl) => {
    setSelectedMediaIds((prevSelectedMediaIds) => {
      if (prevSelectedMediaIds.includes(imageUrl)) {
        return prevSelectedMediaIds.filter((url) => url !== imageUrl);
      } else {
        return [...prevSelectedMediaIds, imageUrl];
      }
    });
  };

  const handleMoveUp = async (index) => {
    if (index > 0) {
      setPlaylist((prevPlaylist) => {
        const newPlaylist = [...prevPlaylist];
        [newPlaylist[index - 1], newPlaylist[index]] = [newPlaylist[index], newPlaylist[index - 1]];
        return newPlaylist;
      });

      // Mettre à jour la playlist dans le backend après chaque modification
      await updatePlaylistInBackend(playlist);
    }
  };

  const handleMoveDown = async (index) => {
    if (index < playlist.length - 1) {
      setPlaylist((prevPlaylist) => {
        const newPlaylist = [...prevPlaylist];
        [newPlaylist[index], newPlaylist[index + 1]] = [newPlaylist[index + 1], newPlaylist[index]];
        return newPlaylist;
      });

      // Mettre à jour la playlist dans le backend après chaque modification
      await updatePlaylistInBackend(playlist);
    }
  };

  const updatePlaylistInBackend = async (updatedPlaylist) => {
    try {
      await axios.post(
        'https://scoreboardapi.onrender.com/api/update-playlist',
        { playlist: updatedPlaylist },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la playlist dans le backend', error);
    }
  };

  const handleAddToPlaylist = async () => {
    const playlistData = selectedMediaIds.map((mediaId, index) => {
      const media = imageList[mediaId];
      const duration = media.duration !== "" ? parseInt(media.duration) : null; // Convertir en nombre
      return {
        url: media.url,
        duration: media.url.startsWith("https://images.pub-tv.net/WaterlooDucks") ? duration : null,
        order: playlist.length + index,
      };
    });

    try {
      await axios.post("https://scoreboardapi.onrender.com/api/add-to-playlist", {
        playlist: playlistData,
        duration: selectedFileDuration,
      });

      const newPlaylist = [...playlist, ...playlistData];
      setPlaylist(newPlaylist);

      setSelectedMediaIds([]);
    } catch (error) {
      console.error("Erreur lors de l'ajout des publicités à la playlist", error);
    }
  };

  const handleDeletePlaylist = async (imageUrl) => {
    try {
      // Supprimer l'élément de la playlist dans le backend
      await axios.delete('https://scoreboardapi.onrender.com/api/delete-from-playlist', {
        data: { url: imageUrl },
      });

      // Mettre à jour la playlist locale en supprimant l'élément
      const newPlaylist = playlist.filter((item) => item.url !== imageUrl);
      setPlaylist(newPlaylist);
    } catch (error) {
      console.error("Erreur lors de la suppression du média de la playlist", error);
    }
  };

  const fetchPlaylist = async () => {
    try {
      const response = await axios.get('https://scoreboardapi.onrender.com/api/playlist');
      const playlistData = response.data.playlist;
      setPlaylist(playlistData);
    } catch (error) {
      console.error("Erreur lors de la récupération de la playlist", error);
    }
  };
  useEffect(() => {
    fetchPlaylist();
  }, []);


  console.log("image liste", imageList);

  // LOGO 
  const handleLogoUpload = async () => {
    if (!selectedLogo || !teamName || !textColor) {
      alert('Veuillez remplir tous les champs.');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('image', selectedLogo);
      formData.append('teamName', teamName);
      formData.append('textColor', textColor);
  
      // Envoyez la requête au backend pour télécharger le logo
      const response = await axios.post(
        'https://scoreboardapi.onrender.com/api/upload-logo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      const logoUrl = response.data.imageUrl;
      console.log('URL du logo téléchargé:', logoUrl);
  
      // Mettez à jour les données du VISITOR localement
      setTeamName('');
      setTextColor('#000000');
      setSelectedLogo(null);
  
    } catch (error) {
      console.error('Erreur lors du téléchargement du logo:', error);
    }
  };
  


  return (
    <div>
      <div className='pub'>
        <div className='box-add'>
          <img src='https://images.pub-tv.net/app/WD/logo.png' />
          <div className='button-start'>
            <button onClick={handleStartAds}>Lancer les pubs</button>
            <button onClick={handleStopAds}>Arrêter les pubs</button>
          </div>
          <br></br>
          <div className='upload-zone'>
            <input type="file" className='file-upload' onChange={handleFileChange} />
            <input
              type="number"
              placeholder="Durée de diffusion secondes)"
              value={selectedFileDuration}
              onChange={(event) => setSelectedFileDuration(event.target.value)}
            />
            <button onClick={handleUpload}>Télécharger</button>
          </div>
          {/* VISITOR INPUT */}
          <div className='visitor'>
            <p>Visitor</p>
            <div className='upload-zone'>
              <input type="file" className='file-upload' onChange={(event) => setSelectedLogo(event.target.files[0])} />
              <input
                type="text"
                placeholder="Nom de l'équipe"
                value={teamName}
                onChange={(event) => setTeamName(event.target.value)}
              />
              <input
                type="color"
                value={textColor}
                onChange={(event) => setTextColor(event.target.value)}
              />
              <button onClick={handleLogoUpload}>OK</button>
            </div>
          </div>

        </div>
        <div className='border-media'>
          <p className='title-pub'>bibliothèque de medias</p>
          {dataReady ? (
            <div className='mediatheque'>
              <ul>
                {imageList.map((media, index) => (
                  <li key={index}>
                    {media && media.url && media.url.endsWith && media.url.endsWith('.mp4') ? (
                      <video controls className='video-minature'>
                        <source src={media.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      media && media.url && <img src={media.url} alt={`Média ${index}`} />
                    )}
                    {media && media.url && (
                      <>
                        <input
                          type="checkbox"
                          checked={selectedMediaIds.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <button onClick={() => handleDelete(media.url)}>Supprimer</button>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p>Chargement en cours...</p>
          )}
          <button className='add-playlist-button' onClick={handleAddToPlaylist}>Ajouter à la Playlist</button>
        </div>

        <div className='playlist'>
          <p className='title-pub'>Playlist de diffusion</p>
          <ul>
            {playlist.map((item, index) => (
              <li key={index}>
                {item.url.endsWith('.mp4') ? (
                  <video controls className='video-minature'>
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={item.url} alt={`Image ${index}`} />
                )}
                <div className='playlist-button'>
                  {/* <button onClick={() => handleMoveUp(index)}>Moins</button>
                <button onClick={() => handleMoveDown(index)}>Plus</button> */}
                </div>
                <button className='playlist-button-supp' onClick={() => handleDeletePlaylist(item.url)}>Supprimer de la playlist</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

};

export default PubPage;