// TimerContext.js
import React, { createContext, useContext, useState } from 'react';

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [time, setTime] = useState('20:00');
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [pausedTimestamp, setPausedTimestamp] = useState(null);

console.log('time:', time);
console.log('isTimerRunning:', isTimerRunning);
console.log('pausedTimestamp:', pausedTimestamp);


  return (
    <TimerContext.Provider
      value={{ time, setTime, isTimerRunning, setIsTimerRunning, pausedTimestamp, setPausedTimestamp }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
