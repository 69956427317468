// App.js
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Commande from './Commande';
import Scoreboard from './Scoreboard';
import { TimerProvider } from './TimerContext';

const App = () => {
  return (
    <TimerProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Scoreboard} />
          <Route path="/commande" component={Commande} />
        </Switch>
      </Router>
    </TimerProvider>
  );
};

export default App;
