import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './commande.css';
import PubPage from './pub';
import { useTimer } from './TimerContext'; // Import the TimerContext


const Commande = () => {
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [homeScore, setHomeScore] = useState(0);
  const [visitorScore, setVisitorScore] = useState(0);
  const [period, setPeriod] = useState(1);
  const [filledCells, setFilledCells] = useState(0); // Define and initialize filledCells state


  const [pausedTimestamp, setPausedTimestamp] = useState(null);

  const { time, setTime, isTimerRunning, setIsTimerRunning } = useTimer();



  const sliderImages = [
    'https://images.pub-tv.net/app/WD/1.png',
    'https://images.pub-tv.net/app/WD/2.png',
    'https://images.pub-tv.net/app/WD/3.png',
    'https://images.pub-tv.net/app/WD/4.png',
    'https://images.pub-tv.net/app/WD/5.png',
    'https://images.pub-tv.net/app/WD/6.png',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
    }, 5000);

    return () => clearInterval(sliderInterval);
  }, [sliderImages.length]);

  useEffect(() => {
    const calculateFilledCells = () => {
      const totalCells = 10;
      const totalTime = 1200;

      const [minutes, seconds] = time.split(':').map(Number);
      const currentTimeInSeconds = minutes * 60 + seconds;

      return Math.min(totalCells, Math.floor((currentTimeInSeconds / totalTime) * totalCells));
    };

    setFilledCells(calculateFilledCells());
  }, [time]);

  // Inside your Commande component
  const interval = useRef(null);

  const startTimer = () => {
    if (!isTimerRunning) {
      clearInterval(interval.current); // Clear any existing intervals
      
      if (pausedTimestamp) {
        setIsTimerRunning(true);
        const currentTime = new Date().getTime();
        const remainingTime = pausedTimestamp - currentTime;
  
        interval.current = setInterval(() => {
          if (remainingTime <= 0) {
            clearInterval(interval.current);
            setIsTimerRunning(false);
            setPausedTimestamp(null);
          } else {
            const newCurrentTime = new Date().getTime();
            const elapsedTime = currentTime - newCurrentTime;
            const adjustedRemainingTime = remainingTime - elapsedTime;
  
            if (adjustedRemainingTime <= 0) {
              clearInterval(interval.current);
              setIsTimerRunning(false);
              setPausedTimestamp(null);
            } else {
              setPausedTimestamp(newCurrentTime + adjustedRemainingTime);
            }
          }
        }, 1000);
      } else {
        const [initialMinutes, initialSeconds] = time.split(':').map(Number);
        let initialTimeInSeconds = initialMinutes * 60 + initialSeconds;

        setIsTimerRunning(true);
        clearInterval(interval.current);

        interval.current = setInterval(() => {
          if (initialTimeInSeconds === 0) {
            clearInterval(interval.current);
            setIsTimerRunning(false);
            // You might want to perform additional actions here when the timer finishes
          } else {
            initialTimeInSeconds -= 1;
            const newMinutes = Math.floor(initialTimeInSeconds / 60);
            const newSeconds = initialTimeInSeconds % 60;
            setTime(`${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`);
          }
        }, 1000);
      }
    }
  };


  const stopTimer = () => {
    if (isTimerRunning) {
      clearInterval(interval.current);
      const currentTime = new Date().getTime();
      const pausedTimeInMilliseconds = currentTime - pausedTimestamp;
      setPausedTimestamp(prevTimestamp => prevTimestamp - 1000);
      setIsTimerRunning(false);
    }
  };
  

  const handleLogin = async () => {
    try {
      // Début du chargement
      setIsLoading(true);

      const response = await axios.post('https://scoreboardapi.onrender.com/auth/login', {
        password,
      });

      // Fin du chargement
      setIsLoading(false);

      if (response.data.success) {
        setIsLoggedIn(true);
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      // Fin du chargement
      setIsLoading(false);

      setErrorMessage('Erreur du serveur.');
    }
  };

  const apiUrl = 'https://scoreboardapi.onrender.com/api/scoreboard';

  const handleUpdateScoreboard = async () => {
    try {
      await axios.post(apiUrl, {
        homeScore,
        visitorScore,
        period,
        time: isTimerRunning ? time : '20:00', // Utilize the time state here
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du scoreboard', error);
    }
  };
  

  const handleGoalChange = (team, change) => {
    if (team === 'home') {
      setHomeScore((prevScore) => Math.max(prevScore + change, 0));
    } else if (team === 'visitor') {
      setVisitorScore((prevScore) => Math.max(prevScore + change, 0));
    }
  };

  const handlePeriodChange = (change) => {
    setPeriod((prevPeriod) => Math.max(prevPeriod + change, 1));
  };

  useEffect(() => {
    if (isTimerRunning) {
      handleUpdateScoreboard();
    }
  }, [isTimerRunning]);

  useEffect(() => {
    if (isLoggedIn) {
      handleUpdateScoreboard();
    }
  }, [isLoggedIn, homeScore, visitorScore, period, time]);

  const handleGoalIncrement = (team) => {
    handleGoalChange(team, 1);
  };

  const handleGoalDecrement = (team) => {
    handleGoalChange(team, -1);
  };

  const handlePeriodIncrement = () => {
    handlePeriodChange(1);
  };

  const handlePeriodDecrement = () => {
    handlePeriodChange(-1);
  };
  if (isLoggedIn) {
    return (
      <div className='container'>
        <div className='commande'>
          <div className='header-bar'>
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className={index < filledCells ? 'cell' : 'empty-cell'}
              />
            ))}
          </div>
          <div className='time-input'>
            <div className='time-display'>
              <label htmlFor='time'>Temps</label>
              <input
                type='text'
                id='time'
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div className='score'>
              <div className='homeScore'>
                <label htmlFor='homeScore'>Home</label>
                <input
                  type='number'
                  id='homeScore'
                  value={homeScore}
                  onChange={(e) => setHomeScore(Number(e.target.value))}
                />
              </div>
              <div className='add-button'>
                <button onClick={() => handleGoalChange('home', 1)}>+</button>
                <button onClick={() => handleGoalChange('home', -1)}>-</button>
              </div>
              <div className='visitorScore'>
                <label htmlFor='visitorScore'>Visitor</label>
                <input
                  type='number'
                  id='visitorScore'
                  value={visitorScore}
                  onChange={(e) => setVisitorScore(Number(e.target.value))}
                />
              </div>
              <div className='add-button'>
                <button onClick={() => handleGoalIncrement('visitor')}>+</button>
                <button onClick={() => handleGoalDecrement('visitor')}>-</button>
              </div>
            </div>
            <div className='period'>
              <label htmlFor='period'>Period</label>
              <input
                type='number'
                id='period'
                value={period}
                onChange={(e) => setPeriod(Number(e.target.value))}
              />
              <div className='add-button-period'>
                <button onClick={handlePeriodIncrement}>+</button>
                <button onClick={handlePeriodDecrement}>-</button>
              </div>
            </div>
          </div>
          <div className='button-start'>
            <button onClick={startTimer}>Démarrer le Chronomètre</button>
            <button onClick={stopTimer}>Arrêter le Chronomètre</button>
          </div>
        </div>
        <div>
          <PubPage />
        </div>
      </div>
    );
  }

  return (
    <div className='container-login'>
      <div className='header-login'>
        <div className='header-banner'>
          <img src='https://images.pub-tv.net/app/WD/logo.png' alt='Logo' />
        </div>
        <div className='slider'>
          {sliderImages.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Image ${index + 1}`}
              className={index === currentImageIndex ? 'active' : 'inactive'}
            />
          ))}
        </div>
      </div>
      <div className='login'>
        <div className='glass box'>
          <svg
            className='user-img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
          >
            <path
              fill='currentColor'
              d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'
            />
          </svg>
          <div className='user-name'>Waterloo Ducks Hockey</div>
          <input
            className='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder='Mot de passe'
          />
          <button className='login-button' onClick={handleLogin}>
            Connexion
          </button>
          {isLoading ? (
            <div className='loader'></div>
          ) : errorMessage ? (
            <p style={{ color: 'red' }}>Erreur MDP !</p>
          ) : null}

        </div>
      </div>
    </div>
  );
};

export default Commande;
