import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Scoreboard.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Scoreboard = () => {
  const [scoreboardData, setScoreboardData] = useState({
    homeScore: 0,
    visitorScore: 0,
    period: 1,
    time: '00:00', // Default timer value
  });

  const [visitorInfo, setVisitorInfo] = useState(null); // State to store visitor information

  const [showFullScreenAd, setShowFullScreenAd] = useState(false);
  const [adPlaylist, setAdPlaylist] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [media, setMedia] = useState(null);

  useEffect(() => {
    const fetchScoreboardData = async () => {
      try {
        const [scoreboardResponse, adsStateResponse] = await Promise.all([
          axios.get('https://scoreboardapi.onrender.com/api/scoreboard'),
          axios.get('https://scoreboardapi.onrender.com/api/ads-state'),
        ]);

        if (scoreboardResponse.data) {
          setScoreboardData(scoreboardResponse.data);
        }

        if (adsStateResponse.data) {
          const newAdsRunning = adsStateResponse.data.adsRunning;

          if (!showFullScreenAd && newAdsRunning) {
            setShowFullScreenAd(true);
          } else if (!newAdsRunning) {
            setShowFullScreenAd(false);
            setMedia(null);
            setCurrentAdIndex(0); // Reset the ad index
          }
        }
      } catch (error) {
        console.error('Error fetching scoreboard data:', error);
      }
    };

    fetchScoreboardData();
    const interval = setInterval(fetchScoreboardData, 100);

    return () => {
      clearInterval(interval);
      setMedia(null);
      setCurrentAdIndex(0);
    };
  }, [showFullScreenAd]);

  useEffect(() => {
    const fetchAdPlaylist = async () => {
      if (showFullScreenAd) {
        try {
          const playlistResponse = await axios.get('https://scoreboardapi.onrender.com/api/playlist');
          if (playlistResponse.data) {
            setAdPlaylist(playlistResponse.data.playlist);
          }
        } catch (error) {
          console.error('Error fetching ad playlist:', error);
        }
      }
    };

    fetchAdPlaylist();
  }, [showFullScreenAd]);

  useEffect(() => {
    if (showFullScreenAd && adPlaylist.length > 0) {
      const currentAd = adPlaylist[currentAdIndex];
      if (currentAd) {
        setMedia(currentAd);
      }
    }
  }, [showFullScreenAd, currentAdIndex, adPlaylist]);

  useEffect(() => {
    if (media) {
      const adDuration = media.duration * 1000;
      const timeout = setTimeout(() => {
        setCurrentAdIndex(prevIndex => (prevIndex + 1) % adPlaylist.length);
      }, adDuration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [media, adPlaylist]);

  // Fetch visitor information
  useEffect(() => {
    const fetchVisitorInfo = async () => {
      try {
        const response = await axios.get('https://scoreboardapi.onrender.com/api/get-visitor');
        if (response.data) {
          setVisitorInfo(response.data);
        }
      } catch (error) {
        console.error('Error fetching visitor information:', error);
      }
    };
  
    // Effect to fetch visitor information initially
    fetchVisitorInfo();
  
    // Interval to fetch visitor information every 30 seconds
    const intervalId = setInterval(fetchVisitorInfo, 30000);
  
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className='scoreboard'>
      <div className='scoreboard-border'>
        <Link to="/commande" className="full-screen-link" />
        <div className='goal-home'>
          <h2 className='goal-title'>WATDUCKS</h2>
          <p className='goal-numb'>{scoreboardData.homeScore}</p>
          <img className='logo' src="https://images.pub-tv.net/app/WD/logotete" alt="" width={'180px'} />        </div>
        <div className='time'>
          <p className='time-period'>{scoreboardData.period}</p>
          <h2 className='time-title'>PERIOD</h2>
          <p className='time-numb'>{scoreboardData.time}</p>
        </div>
        <div className='goal-visitor'>
          {visitorInfo ? (
              <h2 style={{ color: visitorInfo.textColor}} className='goal-title'>{visitorInfo.teamName}</h2>
          ) : (
            <div style={{ color: 'black' }}>VISITOR</div>
          )}
          <p className='goal-numb'>{scoreboardData.visitorScore}</p>
          <img className='logo' src={visitorInfo && visitorInfo.logoUrl} alt="" width={'180px'} />

        </div>
      </div>
      {showFullScreenAd && media && (
        <div className="fullscreen-ad">
          {media.url.endsWith('.mp4') ? (
            <video
              key={media.url}
              src={media.url}
              autoPlay
              muted
              playsInline
              loop
              controls={false}
              onEnded={() => {
                if (adPlaylist.length > 1) {
                  setCurrentAdIndex(prevIndex => (prevIndex + 1) % adPlaylist.length);
                }
              }}
              style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9999,
                backgroundColor: 'black',
              }}
            />
          ) : (
            <img
              src={media.url}
              alt={`Fullscreen Ad ${currentAdIndex}`}
              style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9998,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Scoreboard;
